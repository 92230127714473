import { ConfigService } from "resources/services/ConfigService";
import { IQuestionnaireDialogSettings, QuestionnaireDialogContainer } from "./questionnaire-dialog-container";
import { ReportService } from "resources/services/ReportService";
import { NitTools } from "resources/classes/NursitTools";
import {fhirEnums} from "resources/classes/fhir-enums";
import { DialogMessages } from "resources/services/DialogMessages";
import { Questionnaire } from "resources/classes/FhirModules/Questionnaire";
import {UserService} from "../../services/UserService";
import { QuestionnaireService } from 'resources/services/QuestionnaireService';

export class MedboardQuestionnaireContainer extends QuestionnaireDialogContainer {
    /** Gets or sets the name of the report to print */
    reportName: string = undefined;
    /** Gets or sets the message to be displayed in the bound title property of the print-button */
    reportError : string = '';
    /** Gets or sets a value indicating whether printing a report is possible at all (like report has been found) */
    reportPossible : boolean = false;
    /** Gets or sets a value indicating whether the print-button is enabled. Combines from reportPossible and response status */
    reportEnabled : boolean = false;

    settings: IQuestionnaireDialogSettings;

    /** gives a message, when the questionnaire is not in status 'active' but has been loaded from the server to be able to display the response */
    questionnaireHint : string;

    /** Is executed when the form-values have changed */
    override formChanged(sender: Questionnaire): void {
        super.formChanged(sender);
    }

    override async afterResponseChanged(response: any): Promise<void> {
        await super.afterResponseChanged(response);
        if (NitTools.IsArray(response?.item)) {
            this.responseBackup = NitTools.Clone(response.item);
            if (ConfigService.Debug) {
                console.log(`Updated response backup`);
            }
        }
    }

    override async activate(settings?: IQuestionnaireDialogSettings) {
        this.afterDateEdited = (newDate : string) => {
            if (!newDate || !this.response || newDate === this.response?.authored) return;

            this.response.authored = newDate;
        }

        await super.activate(settings);
        this.settings = settings;

        if (!settings?.questionnaire?.name) return;
        const questionnaireName = settings.questionnaire?.name?.toUpperCase();

        // try to get the configuration for the selected questionnaire by name or route
        const config = ConfigService.cfg?.forms?.find(o => o.questionnaireName?.toUpperCase() === questionnaireName || o.route.toUpperCase() === questionnaireName);

        // either use the configured report.name or the questionnairename as report name
        this.reportName = String(config?.report?.name || settings.questionnaire.name).toUpperCase();

        // ReportServer returns just the filenames, which always have the frx extension. So add it to our reportName if not exists
        if (this.reportName && this.reportName.indexOf('.FRX') === -1)
            this.reportName += '.FRX';

        // get all reports from the Report-Server
        const reports = await ReportService.Fetch();
        if (!NitTools.IsArray(reports?.items) || reports.items.length === 0) return;

        // check if the estimated reportName exists in the reports the Server knows
        if (typeof reports.items.find(o=>String(o).toUpperCase() === this.reportName) !== "undefined") {
            this.reportError = this.i18n.tr("print");
            this.reportPossible = true;
        } else {
            this.reportError = `No suitable Report found for this Questionnaire`;
            this.reportPossible = false;
        }

        this.report = this.reportName;
    
        // update the disabled state of the print button
        this.checkReportButtonStatus();

        if (ConfigService.Debug) {
            window["mbq"] = this;

            if (this.reportPossible) {
                console.log(`Found and using report: "${this.reportName}"`);
            } else {
                console.warn(`No report for Questionnaire "${this.questionnaire?.name}" found`);
            }
        }

        this.previousResponse = QuestionnaireService.GetLatestResponseOfType(settings.patient, settings.questionnaire?.id, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);


        if (UserService.IsReadonly) {
            this.showSaveButton = this.settings.showDateEdit = false;
            this.forceNoChange = this.readonly = this.forcedReadonly = true;
        }
    }

    setPrintButtonStatus(enabled: boolean) {
        const buttons = document.querySelectorAll(`[data-context="print"]`);
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            if (enabled) {
                button.removeAttribute("disabled");
            } else {
                button.setAttribute("disabled", "disabled");
            }
        }
    }

    override async printButtonClicked(isMultiPrint?: boolean, openPfd?: boolean) {
        // Don't use: await super.printButtonClicked(isMultiPrint, openPfd);

         if (!this.reportPossible) {
             DialogMessages.Prompt(this.dialogService, this.i18n.tr('no_matching_report_found'), this.i18n.tr("information"), true);
             this.setPrintButtonStatus(false);
             return;
         }

        if (!this.readonly && this.hasChanges) {
            this.showSaveFirstDialog();
            return;
        }

        if (['amended', 'completed'].indexOf(this.response?.status) === -1) {
            DialogMessages.Prompt(this.dialogService, this.i18n.tr('response_not_finalized_for_print'), this.i18n.tr("information"), true);
            return;
        }

        ReportService.Preview(this.response.id, this.reportName, undefined, !!openPfd);
    }
    
    /** Update the reportEnabled state, depending on found report and response status */
    checkReportButtonStatus() {
        // no report found, so it's not possible to print anything
        if (!this.reportPossible || !this.response?.status) {
            this.reportEnabled = false;
            return;
        }

        // only allow finalized responses to be printed
        this.reportEnabled = ['completed', 'amended'].indexOf(this.response?.status) > -1;
        if (!this.reportEnabled) {
            this.reportError = 'Response not finalized';
        }
    }

    override async abortButtonClicked() {
        if (!this.hasChanges) {
            this.close(true);
        } else {
            await DialogMessages.Dialog(this.dialogService, this.i18n.tr('confirm_discard_unsaved_changes'), this.i18n.tr('confirm'), this.i18n.tr('yes'), this.i18n.tr('no'), true)
            .whenClosed(o => {
                if (o.wasCancelled || o.output === false)
                    return;

                this.close(true);
            });
        } 
    }

    /** is called when the user clicks the save-button. Overrides the closing behaviour of the form */
    override saveButtonClicked(status = undefined, closeForm : boolean = true) {
        // only close the questionnaire if printing is not possible either because the response is saved as in-progress or report is not possible
        // we leave the form open when the user has saved as finalized (status 'completed' or 'amended')
        closeForm = this.response?.status == 'in-progress';
        super.saveButtonClicked(status, false);

        // update the status of the report-button when the form stays open
        if (!closeForm)
            this.checkReportButtonStatus();
        
        if (NitTools.IsArray(this.response?.item))
            this.responseBackup = NitTools.Clone(this.response.item);

        /* if (closeForm) : as requested by Stoyan, always close the form on save*/
        this.close(false);
    }
}
